<template>
    <div class="pl-4 text-center">
      <v-dialog v-model="dialog" @open="onOpen">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            color="error"
            class="text-none font-weight-regular"
            prepend-icon="mdi-delete"
            text="Delete Project"
            variant="tonal"
            v-bind="activatorProps"
            block
            size="small"

          ></v-btn>
        </template>
        <v-card
          v-if="localProject"
          prepend-icon="mdi-delete"
          title="Delete Project"
          :subtitle="localProject.name"
        >
          <v-card-text> Are you sure you want to delete this project? </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn text="Cancel" variant="plain" @click="dialog = false"></v-btn>
  
            <v-btn
              color="error"
              text="Delete"
              variant="tonal"
              @click="deleteProject"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script setup>
  import { shallowRef, ref, watch } from "vue";
  import { useProjectStore } from "@/stores/project";
  import { useResponseStore } from "@/stores/response";
  
  const dialog = shallowRef(false);
  
  const projectStore = useProjectStore();
  const responseStore = useResponseStore();
  
  const localProject = ref(null);
  
  const props = defineProps({
    project: Object,
  });
  
  // Watch the dialog's state
  watch(dialog, (newVal) => {
    if (newVal) {
      onOpen();
    } else {
      onClose();
    }
  });
  
  // Function to initialize
  const initialize = () => {
    localProject.value = {
      ...props.project,
    };
  };
  
  // const emit = defineEmits(["update:modelValue", "close"]);
  
  const onOpen = async () => {
    initialize();
    responseStore.$reset();
  };
  
  const onClose = () => {};
  
  const deleteProject = () => {
    projectStore.deleteProject(localProject.value.id);
    dialog.value = false;
  };
  </script>
  